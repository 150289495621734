import { t } from "@comact/crc";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import { NodesModule } from "../node";
import { DEFAULT_NODE_ID, ILabel, ILabels, ILabelTypes } from "./model";

export const getLabelsByType = ({ labels }: IStoreState, type: ILabelTypes) => {
    if (!labels) return null;
    return _.chain(labels)
        .filter((label) => label.type == type)
        .orderBy(({ code }) => code)
        .value();
};

export const getMachineLabelsByType = (state: IStoreState, nodeId: string, type: ILabelTypes): ILabels => {
    const defaultLabels = _.chain(state.labels)
        .pickBy((l) => l.type == type && l.nodeId == DEFAULT_NODE_ID)
        .keyBy(({ code }) => code)
        .value();

    const nodeLabels = _.chain(state.labels)
        .pickBy((l) => l.type == type && l.nodeId == nodeId)
        .keyBy(({ code }) => code)
        .value();
    return _.keyBy({ ...defaultLabels, ...nodeLabels }, ({ id }) => id);
};
export const getDefaultNonOverridenLabelsByType = memoize(({ state, downtimeNodeIds, type }: { state: IStoreState; downtimeNodeIds: string[]; type: ILabelTypes; }) => {
    const downtimeCodesForSpecificMachines = _.chain(getLabelsByType(state, type))
        .filter(({ nodeId }) => _.includes(downtimeNodeIds, nodeId))
        .map(({ code }) => code)
        .uniq()
        .value();

    return _.filter(getMachineLabelsByType(state, DEFAULT_NODE_ID, type), ({ code }) => !_.includes(downtimeCodesForSpecificMachines, code));
});

export const getMachineLabelByTypeAndCode = ((state: IStoreState, code: number, nodeId: string, type: ILabelTypes): ILabel => (
    _.find(getMachineLabelsByType(state, nodeId, type), (l) => l.code == code)
));

export const getLabelByTypeAndCode = ((state: IStoreState, code: number, type: ILabelTypes): ILabel => (
    _.find(getLabelsByType(state, type), (l) => l.code == code)
));

export const getMachineName = (state: IStoreState, nodeId: string) => {
    if (nodeId == "-1") return t("machines.unassigned");

    return NodesModule.selectors.getNodeById(state, nodeId)?.name ?? t("labels.default");
};