import { app, FPS, store } from "@comact/crc";
import sprintf from "i18next-sprintf-postprocessor";
import { BatchesModule, requests as batchRequests } from "js/batches";
import { DashboardsModule } from "js/dashboards";
import { DatabaseDefinitionsModule } from "js/databaseDefinitions";
import { IntervalsModule } from "js/intervals";
import { ContextDefinitionsModule } from "js/kpis/contextDefinitions";
import { KpiDataIOModule } from "js/kpis/kpiDataIO";
import { KpiDataIOModuleEdit } from "js/kpis/kpiDataIO/edition";
import { LineSpeedsModule } from "js/lineSpeeds";
import { MUnitModule } from "js/mUnits";
import { ReportConfigurationsModule } from "js/reports/configurations";
import { ReportsJobsModule } from "js/reports/jobs";
import { ReportsJobsEditModule } from "js/reports/jobs/edit";
import { ReportsTemplatesModule } from "js/reports/templates";
import { reducers as timbersReducers } from "js/timberViewer";
import { UserPrefsModule } from "js/userPrefs";
import _ from "lodash";
import * as React from "react";
import { Route } from "react-router";
import { Navigate } from "react-router-dom";
import { BackupsModule } from "./backups";
import { CloudAgentModule } from "./cloudAgent";
import { CloudFilesModule } from "./cloudFiles";
import { DowntimesModule } from "./downtimes";
import { ELearningModule } from "./elearning";
import { SystemEventsModule } from "./events";
import { ExternalProviderModule } from "./externalProvider";
import { FeatureFlagsModule } from "./featureFlags";
import { GrafanaIntegrationModule } from "./grafanaIntegration";
import { KpiAlertsModule } from "./kpis/kpiAlerts";
import { KpiPatternEditorsModule } from "./kpis/kpiPatternEditors";
import { KpiPatternEditorFormulaModule } from "./kpis/kpiPatternEditors/formula";
import { KpiPatternsModule } from "./kpis/kpiPatterns";
import { LabelGroupsModule } from "./labelGroups";
import { LabelsModule } from "./labels";
import { ModelsModule } from "./models";
import { NodesModule } from "./node";
import { correctHomeUrl } from "./node/components/PageOverview";
import { getSitemap, getSystemMenu } from "./node/perspectives/selectors";
import { TemplatesModule } from "./nodeTemplate";
import { NotificationsModule } from "./notifications";
import { GrafanaAlertsModule } from "./notifications/grafanaAlerts";
import { ProductsModule } from "./products";
import { ReportsBrowserModule } from "./reports/browser";
import { ResourceLibraryModule } from "./resourceLibrary";
import { SamplesModule } from "./samples";
import { ShiftsModule } from "./shifts";
import { SmartVisionModule } from "./smartVision";
import { DensitiesBySpeciesModule } from "./speciesDensity";
import { TagStrategiesModule } from "./tagStrategies";
import { ThirdPartyIntegrationModule } from "./thirdPartyIntegration";
import { WatchlistModule } from "./watchlist";

const PageOverviewRedirect = React.lazy(() => import("./node/components/PageOverview").then((m) => ({ default: m.PageOverviewRedirect })));
const PageOverview = React.lazy(() => import("./node/components/PageOverview"));
const PageEvents = React.lazy(() => import("./events/components/PageSystemEvents"));
const PageSearchPickAndPlaceEvents = React.lazy(() => import("./events/components/PageSearchPickAndPlaceEvents"));
const PageDashboard = React.lazy(() => import("./dashboards/components/PageDashboard"));
const PageDashboardUserEvents = React.lazy(() => import("./dashboards/components/PageDashboardUserEvents"));
const PageDashboardEdit = React.lazy(() => import("./dashboards/dashboardsEdits/components/PageDashboardEdit"));
const PageDashboardList = React.lazy(() => import("./dashboards/dashboardsEdits/components/PageDashboardList"));
const PageShifts = React.lazy(() => import("./shifts/components/PageShifts"));
const PageShiftDefinitionEdit = React.lazy(() => import("./shifts/components/PageShiftDefinitionEdit"));
const PageIntervals = React.lazy(() => import("./intervals/components/PageIntervals"));
const PageLabels = React.lazy(() => import("./labels/components/PageLabels"));
const PageLabelsEdit = React.lazy(() => import("./labels/components/PageLabelsEdit"));
const PageLabelGroups = React.lazy(() => import("./labelGroups/components/PageLabelGroups"));
const PageLabelGroupsEdit = React.lazy(() => import("./labelGroups/components/PageLabelGroupsEdit"));
const PageKpiPatternsList = React.lazy(() => import("./kpis/kpiPatterns/components/PageKpiPatternsList"));
const PageKpiPatternEditor = React.lazy(() => import("./kpis/kpiPatternEditors/components/PageKpiPatternEditor"));
const PageKpiAlerts = React.lazy(() => import("./kpis/kpiAlerts/components/PageKpiAlerts"));
const PageKpiAlertEdit = React.lazy(() => import("./kpis/kpiAlerts/components/PageKpiAlertEdit"));
const PageContextDefinitionsEdit = React.lazy(() => import("./kpis/contextDefinitions/components/PageContextDefinitionsEdit"));
const PageContextDefinitions = React.lazy(() => import("./kpis/contextDefinitions/components/PageContextDefinitions"));
const PageReportBrowser = React.lazy(() => import("./reports/browser/components/PageReportBrowser"));
const PageReportJobList = React.lazy(() => import("./reports/jobs/components/PageReportJobList"));
const PageReportJobEditor = React.lazy(() => import("./reports/jobs/edit/components/PageReportJobEditor"));
const PageReportConfigurations = React.lazy(() => import("./reports/configurations/components/PageReportConfigurations"));
const PageReportConfigurationEdit = React.lazy(() => import("./reports/configurations/components/PageReportConfigurationEdit"));
const PageSpeciesDensity = React.lazy(() => import("./speciesDensity/components/PageSpeciesDensity"));
const PageSpeciesDensityEdit = React.lazy(() => import("./speciesDensity/components/PageSpeciesDensityEdit"));
const PageKpiDataIOs = React.lazy(() => import("./kpis/kpiDataIO/components/PageKpiDataIOs"));
const PageDetails = React.lazy(() => import("./node/components/PageDetails"));
const PageNodeEdit = React.lazy(() => import("./node/components/PageNodeEdit"));
const PageLinksEdit = React.lazy(() => import("./node/components/PageLinksEdit"));
const PageLogin = React.lazy(() => import("./users/components/PageLogin"));
const PageOrganizationSelector = React.lazy(() => import("./users/components/PageOrganizationSelector"));
const PageTemplatesList = React.lazy(() => import("./nodeTemplate/components/PageTemplatesList"));
const PageCloudFiles = React.lazy(() => import("./cloudFiles/components/PageCloudFiles"));
const PageBackups = React.lazy(() => import("./backups/components/PageBackups"));
const PageActivationHistory = React.lazy(() => import("./backups/components/PageActivationHistory"));
const PageApplicationLogs = React.lazy(() => import("./backups/components/PageApplicationLogs"));
const PageAllUserEvents = React.lazy(() => import("./userEvents/components/PageAllUserEvents"));
const PageCmocBackupsRestore = React.lazy(() => import("./backups/components/PageCmocBackupsRestore"));
const PageModelGroups = React.lazy(() => import("./models/components/PageModelGroups"));
const PageModelVersions = React.lazy(() => import("./models/components/PageModelVersions"));
const PageModelUserEvents = React.lazy(() => import("./models/components/PageModelUserEvents"));
const PageModelActivations = React.lazy(() => import("./models/components/PageModelActivations"));
const PageTagStrategies = React.lazy(() => import("./tagStrategies/components/PageTagStrategies"));
const PageTagStrategyEditor = React.lazy(() => import("./tagStrategies/components/PageTagStrategyEditor"));
const PageBatches = React.lazy(() => import("./batches/components/PageBatches"));
const PageBatchesEdit = React.lazy(() => import("./batches/components/PageBatchesEdit"));
const PageLink = React.lazy(() => import("./links/components/PageLink"));
const PageAlerts = React.lazy(() => import("./alerts/components/PageAlerts"));
const PageCameraListLiveStream = React.lazy(() => import("./cameraLiveStream/components/PageCameraList"));
const PageCloudAgentStats = React.lazy(() => import("./cloudAgent/components/PageCloudAgentStats"));
const PageCloudAgentFiles = React.lazy(() => import("./cloudAgent/components/PageCloudAgentFiles"));
const PageDowntimes = React.lazy(() => import("./downtimes/components/PageDowntimes"));
const PageDowntimeEdit = React.lazy(() => import("./downtimes/components/PageDowntimeEdit"));
const PageWatchlist = React.lazy(() => import("./watchlist/components/PageWatchlist"));
const PageWatchlistItemEdit = React.lazy(() => import("./watchlist/components/PageWatchlistItemEdit"));
const PageZoneConfiguration = React.lazy(() => import("./smartVision/components/PageZoneConfiguration"));
const PageAdvancedZoneConfiguration = React.lazy(() => import("./smartVision/components/PageAdvancedZoneConfiguration"));

const PageUsers = React.lazy(() => import("./users/components/PageUsers"));
const PageUserEdit = React.lazy(() => import("./users/components/PageUserEdit"));
const PageMyPreferences = React.lazy(() => import("./users/components/PageMyPreferences"));
const PageGroups = React.lazy(() => import("./users/components/PageGroups"));
const PageGroupEdit = React.lazy(() => import("./users/components/PageGroupEdit"));
const PageGroupUserEvents = React.lazy(() => import("./users/components/PageGroupUserEvents"));

const PageGrafanaAlerts = React.lazy(() => import("./notifications/grafanaAlerts/components/PageGrafanaAlerts"));
const PageGrafanaAlertEdit = React.lazy(() => import("./notifications/grafanaAlerts/components/PageGrafanaAlertEdit"));
const PageGrafanaIntegration = React.lazy(() => import("./grafanaIntegration/components/PageGrafanaIntegration"));
const PageNotificationGroups = React.lazy(() => import("./notifications/components/PageNotificationGroups"));
const PageNotificationGroupsEdit = React.lazy(() => import("./notifications/components/PageNotificationGroupEdit"));
const PageThirdPartyIntegration = React.lazy(() => import("./thirdPartyIntegration/components/PageThirdPartyIntegration"));

const PageResourceLibrary = React.lazy(() => import("./resourceLibrary/components/PageResourceLibrary"));

const PageExternalProvider = React.lazy(() => import("./externalProvider/components/PageExternalProvider"));

// Initialize the application
app.init({
    customInit: async () => {
        // Check if batch processing is enabled, so we can check later if we can add it to the menu
        await store.dispatch(batchRequests.getBatchConfig())?.promise;
    },
    autoSaveKeys: ["userPrefs", "userOrganizationId"],
    reducers: {
        ...IntervalsModule.reducers,
        ...KpiPatternEditorFormulaModule.reducers,
        ...DatabaseDefinitionsModule.reducers,
        ...ContextDefinitionsModule.reducers,
        ...MUnitModule.reducers,
        ...DashboardsModule.reducers,
        ...ReportsTemplatesModule.reducers,
        ...LabelsModule.reducers,
        ...LabelGroupsModule.reducers,
        ...ContextDefinitionsModule.reducers,
        ...ReportsBrowserModule.reducers,
        ...ReportsJobsModule.reducers,
        ...ReportsJobsEditModule.reducers,
        ...LineSpeedsModule.reducers,
        ...KpiDataIOModule.reducers,
        ...KpiDataIOModuleEdit.reducers,
        ...KpiAlertsModule.reducers,
        ...KpiPatternEditorsModule.reducers,
        ...KpiPatternsModule.reducers,
        ...UserPrefsModule.reducers,
        ...timbersReducers,
        ...ProductsModule.reducers,
        ...TemplatesModule.reducers,
        ...NodesModule.reducers,
        ...CloudFilesModule.reducers,
        ...DensitiesBySpeciesModule.reducers,
        ...ShiftsModule.reducers,
        ...ModelsModule.reducers,
        ...BackupsModule.reducers,
        ...TagStrategiesModule.reducers,
        ...CloudAgentModule.reducers,
        ...BatchesModule.reducers,
        ...DowntimesModule.reducers,
        ...WatchlistModule.reducers,
        ...ReportConfigurationsModule.reducers,
        ...SamplesModule.reducers,
        ...SmartVisionModule.reducers,
        ...SystemEventsModule.reducers,
        ...ELearningModule.reducers,
        ...ExternalProviderModule.reducers,
        ...GrafanaAlertsModule.reducers,
        ...GrafanaIntegrationModule.reducers,
        ...NotificationsModule.reducers,
        ...ResourceLibraryModule.reducers,
        ...ThirdPartyIntegrationModule.reducers,
        ...FeatureFlagsModule.reducers,
    },
    menu: [],
    routes: [

        // Overview
        <Route key="index" path="/" element={<PageOverviewRedirect />} />,
        <Route key="perspective/portal-root" path="/portal" element={<PageOverviewRedirect />} />,
        <Route key="perspective/portal-root/services" path="/portal/services" element={<PageOverviewRedirect />} />,
        <Route key="perspective/overview-root" path="/overview" element={<PageOverview />} />,
        <Route key="perspective/overview" path="/overview/:nodeId" element={<PageOverview />} />,

        // Events
        <Route key="perspective/timeline" path="/events/timeline/:nodeId" element={<PageEvents />} />,

        // Alarms
        <Route key="perspective/alerts" path="/events/alerts/:nodeId" element={<PageAlerts />} />,

        // Search Pick & Place Event
        <Route key="perspective/search-pick-and-place-events" path="/events/search-pick-and-place-events/:nodeId" element={<PageSearchPickAndPlaceEvents />} />,

        // dashboards
        <Route key="perspective/dashboard-view-default" path="/dashboards/view/:nodeId" element={<PageDashboard />} />,
        <Route key="perspective/dashboard-view" path="/dashboards/view/:nodeId/:id" element={<PageDashboard />} />,
        <Route key="perspective/dashboard/events" path="/dashboards/events/:nodeId/:id" element={<PageDashboardUserEvents />} />,
        <Route key="perspective/dashboard-view-list" path="/dashboards/:nodeId" element={<PageDashboardList />} />,
        <Route key="perspective/dashboard-edit" path="/dashboards/edit/:nodeId/:id" element={<PageDashboardEdit />} />,
        <Route key="perspective/dashboard-edit-list/upload-dashboards" path="/dashboards/edit/:nodeId/upload-dashboards/:updatedDashboardIds" element={<PageDashboardList />} />,
        <Route key="perspective/dashboard-edit-list/migrate-dashboards" path="/dashboards/edit/:nodeId/migrate-dashboards/:updatedDashboardIds" element={<PageDashboardList />} />,

        // shifts
        <Route key="perspective/schedule" path="/schedule/:nodeId" element={<PageShifts />} />,
        <Route key="perspective/schedule-action" path="/schedule/:nodeId/:action" element={<PageShiftDefinitionEdit />} />,
        <Route key="perspective/schedule-action-with-id" path="/schedule/:nodeId/:action/:shiftId" element={<PageShiftDefinitionEdit />} />,
        <Route key="perspective/schedule-action-with-date" path="/schedule/:nodeId/:action/start/:startDate/end/:endDate" element={<PageShiftDefinitionEdit />} />,

        // Smart Vision
        <Route key="zone-configuration" path="/zone-configuration/:nodeId" element={<PageZoneConfiguration />} />,
        <Route key="zone-configuration-advanced" path="/zone-configuration-advanced/:nodeId" element={<PageAdvancedZoneConfiguration />} />,

        // Intervals
        <Route key="perspective/parameters/intervals" path="/parameters/intervals/:nodeId" element={<PageIntervals />} />,

        // labels
        <Route key="perspective/parameters/labels" path="/parameters/labels/:category/:nodeId" element={<PageLabels />} />,
        <Route key="perspective/parameters/labels-edit/nodeId" path="/parameters/labels/:category/edit/:nodeId/:editNodeId" element={<PageLabelsEdit />} />,
        <Route key="perspective/parameters/label-groups" path="/parameters/label-groups/:category/:nodeId" element={<PageLabelGroups />} />,
        <Route key="perspective/parameters/label-groups-edit" path="/parameters/label-groups/:category/edit/:nodeId" element={<PageLabelGroupsEdit />} />,
        <Route key="perspective/parameters/label-groups-edit/nodeId" path="/parameters/label-groups/:category/edit/:nodeId/:editNodeId" element={<PageLabelGroupsEdit />} />,

        // kpis
        <Route key="perspective/parameters/kpis" path="/parameters/kpis/:nodeId" element={<PageKpiPatternsList />} />,
        <Route key="perspective/parameters/kpis-create" path="/parameters/kpis/:nodeId/new" element={<PageKpiPatternEditor />} />,
        <Route key="perspective/parameters/kpis-edit" path="/parameters/kpis/:nodeId/:id" element={<PageKpiPatternEditor />} />,

        // kpi alerts
        <Route key="perspective/parameters/kpiAlerts" path="/parameters/kpi-alerts/:nodeId" element={<PageKpiAlerts />} />,
        <Route key="perspective/parameters/kpiAlerts-create-or-edit" path="/parameters/kpi-alerts/:nodeId/:id" element={<PageKpiAlertEdit />} />,

        // Contexts
        <Route key="perspective/parameters/contexts" path="/parameters/contexts/:nodeId" element={<PageContextDefinitions />} />,
        <Route key="perspective/parameters/contexts-edit" path="/parameters/contexts/:nodeId/edit" element={<PageContextDefinitionsEdit />} />,

        // tag strategies
        <Route key="perspective/tag-strategies" path="/parameters/tag-strategies/:nodeId" element={<PageTagStrategies />} />,
        <Route key="perspective/tag-strategies-edit" path="/parameters/tag-strategies/:nodeId/edit/:id" element={<PageTagStrategyEditor />} />,
        <Route key="perspective/tag-strategies-edit-timber-type" path="/parameters/tag-strategies/:nodeId/edit/:id/:newTimberType" element={<PageTagStrategyEditor />} />,

        // Batches
        <Route key="perspective/batches" path="/parameters/batches/:nodeId" element={<PageBatches />} />,
        <Route key="perspective/batches/edit" path="/parameters/batches/edit/:nodeId" element={<PageBatchesEdit />} />,

        // reports
        <Route key="perspective/reportBrowser" path="/reports/:nodeId" element={<PageReportBrowser />} />,
        <Route key="perspective/reportJobs" path="/parameters/reports/jobs/:nodeId" element={<PageReportJobList />} />,
        <Route key="perspective/reportJobs/edit" path="/parameters/reports/jobs/edit/:nodeId/:jobId" element={<PageReportJobEditor />} />,
        <Route key="perspective/reportConfigurations" path="/parameters/reports/configurations/:nodeId" element={<PageReportConfigurations />} />,
        <Route key="perspective/reportConfiguration/edit" path="/parameters/reports/configuration/edit/:nodeId/:reportId" element={<PageReportConfigurationEdit />} />,

        // Species Density
        <Route key="perspective/parameters/species-density" path="/parameters/species-density/:nodeId" element={<PageSpeciesDensity />} />,
        <Route key="perspective/parameters/species-density-edit" path="/parameters/species-density/:nodeId/edit" element={<PageSpeciesDensityEdit />} />,
        <Route key="perspective/parameters/data-io" path="/parameters/data-io/:nodeId" element={<PageKpiDataIOs />} />,

        // Details
        <Route key="perspective/details/root" path="/details" element={<PageDetails />} />,
        <Route key="perspective/details/new" path="/details/new" element={<PageNodeEdit />} />,
        <Route key="perspective/details/new/from-parent" path="/details/:parentId/new" element={<PageNodeEdit />} />,
        <Route key="perspective/details" path="/details/:nodeId" element={<PageDetails />} />,
        <Route key="perspective/details/edit" path="/details/:nodeId/edit" element={<PageNodeEdit />} />,
        <Route key="perspective/details/edit/links" path="/details/:nodeId/edit/links" element={<PageLinksEdit />} />,
        <Route key="perspective/details/open-camera-modal" path="/details/:nodeId/:action" element={<PageDetails />} />,

        // Users + login CMOC
        <Route key="login" path="/login" element={<PageLogin />} />,
        <Route key="organization-selector" path="/organization-selector" element={<PageOrganizationSelector />} />,
        <Route key="user-management/users/redirect" path="/users" element={<Navigate to="/user-management/users" />} />, // Old path given by the service list of the control-center
        <Route key="user-management/users" path="/user-management/users" element={<PageUsers />} />,
        <Route key="user-management/users/edit" path="/user-management/users/edit/:userId" element={<PageUserEdit />} />,
        <Route key="perspective/my-preferences" path="/my-preferences" element={<PageMyPreferences />} />,
        <Route key="user-management/groups" path="/user-management/groups" element={<PageGroups />} />,
        <Route key="user-management/groups/edit" path="/user-management/groups/edit/:groupId" element={<PageGroupEdit />} />,
        <Route key="user-management/events/groups" path="/user-management/events/groups/:groupId" element={<PageGroupUserEvents />} />,

        <Route key="perspective/templates" path="/templates" element={<PageTemplatesList />} />,

        // Cloud files
        <Route key="perspective/cloud-files" path="/cloud-files/:nodeId" element={<PageCloudFiles />} />,

        // Backups
        <Route key="perspective/backups" path="/backups/:backupsType/:nodeId" element={<PageBackups />} />,
        <Route key="perspective/backups/selectedNodeId" path="/backups/:backupsType/:nodeId/:selectedNodeId" element={<PageBackups />} />,
        <Route key="perspective/backups" path="/backups/activation-history/:nodeId" element={<PageActivationHistory />} />,
        <Route key="perspective/backups/selectedNodeId" path="/backups/activation-history/:nodeId/:selectedNodeId" element={<PageActivationHistory />} />,
        <Route key="perspective/backups" path="/backups/application-logs/:nodeId" element={<PageApplicationLogs />} />,
        <Route key="perspective/backups/selectedNodeId" path="/backups/application-logs/:nodeId/:selectedNodeId" element={<PageApplicationLogs />} />,

        // User events
        <Route key="perspective/user-events" path="/user-events" element={<PageAllUserEvents />} />,

        // Cmoc backups restore from external CMOC (opened in a iframe inside the control-center)
        <Route key="cmoc-backups-restore" path="/cmoc-backups-restore" element={<PageCmocBackupsRestore />} />,

        // AI Models
        <Route key="perspective/models" path="/models" element={<PageModelGroups />} />,
        <Route key="perspective/models/activations" path="/models/activations" element={<PageModelActivations />} />,
        <Route key="perspective/models/edit" path="/models/edit/:id" element={<PageModelGroups />} />,
        <Route key="perspective/models/name" path="/models/:name" element={<PageModelVersions />} />,
        <Route key="perspective/models/name/edit" path="/models/:name/edit/:id" element={<PageModelVersions />} />,
        <Route key="perspective/models/events" path="/models/events/:name/:id" element={<PageModelUserEvents />} />,

        // Dynamic links
        <Route key="perspective/links/linkID" path="/link/:nodeId/:linkId" element={<PageLink />} />,

        // Cameras
        <Route key="perspective/camera-list" path="/camera-list/:nodeId" element={<PageCameraListLiveStream />} />,

        // Cloud Agent
        <Route key="perspective/cloudAgent-stats" path="/cloudAgent-stats" element={<PageCloudAgentStats />} />,
        <Route key="perspective/cloudAgent-files-pending" path="/cloudAgent-files-pending" element={<PageCloudAgentFiles />} />,

        // Downtimes
        <Route key="perspective/events/downtime-app/downtimes" path="/events/downtime-app/downtimes/:nodeId" element={<PageDowntimes />} />,
        <Route key="perspective/events/downtime-app/downtimes/edit" path="/events/downtime-app/downtimes/edit/:nodeId/:id" element={<PageDowntimeEdit />} />,

        // Watchlist
        <Route key="perspective/events/downtime-app/watchlist" path="/events/downtime-app/watchlist/:nodeId" element={<PageWatchlist />} />,
        <Route key="perspective/events/downtime-app/watchlist/edit" path="/events/downtime-app/watchlist/edit/:nodeId/:id" element={<PageWatchlistItemEdit />} />,

        // Organization Settings
        <Route key="perspective/organization-settings/external-provider" path="/organization-settings/external-provider" element={<PageExternalProvider />} />,
        <Route key="perspective/organization-settings/grafana-integration" path="/organization-settings/grafana-integration" element={<PageGrafanaIntegration />} />,
        <Route key="perspective/organization-settings/third-party-integration" path="/organization-settings/third-party-integration/:nodeId" element={<PageThirdPartyIntegration />} />,

        // Notifications
        <Route key="perspective/notifications/grafana-alerts" path="/notifications/grafana-alerts" element={<PageGrafanaAlerts />} />,
        <Route key="perspective/notifications/grafana-alerts/edit" path="/notifications/grafana-alerts/edit/:id" element={<PageGrafanaAlertEdit />} />,
        <Route key="perspective/notifications/notification-groups" path="/notifications/notification-groups" element={<PageNotificationGroups />} />,
        <Route key="perspective/notifications/notification-groups/edit" path="/notifications/notification-groups/edit/:id" element={<PageNotificationGroupsEdit />} />,

        // Resource Library
        <Route key="perspective/resource-library" path="/resource-library/:documentType/:nodeId" element={<PageResourceLibrary />} />,
    ],
    getSitemap: async () => {
        while (_.size(store.getState().nodes) == 0) { // nodes are not ready
            await new Promise((r) => setTimeout(r, 200)); // wait a bit before checking again
        }
        const sitemap = getSitemap(store.getState());
        const systemMenu = getSystemMenu(store.getState());
        const urls: string[] = [];

        _.forEach(systemMenu, (menu) => {
            if (!_.isEmpty(menu.subMenu)) {
                _.forEach(menu.subMenu, (subMenu) => {
                    urls.push(subMenu.url);
                });
            } else {
                urls.push(menu.url);
            }
        });
        _.forEach(sitemap, (node) => {
            _.forEach(node.menu, (menu) => {
                if (!_.isEmpty(menu.subMenu)) {
                    _.forEach(menu.subMenu, (subMenu) => { urls.push(subMenu.url); });
                } else {
                    urls.push(menu.url);
                }
            });
        });
        return _.chain(urls).uniq().sort().value();
    },
}).then(async () => {
    await correctHomeUrl();
    setInterval(() => correctHomeUrl(), 60 * 1000); // periodically update the url in case it change
});

app.i18next.use(sprintf);

// Only if not in production, show FPS
if (process.env.NODE_ENV != "production") {
    FPS.renderCallBack = () => FPS.getFramesPerSeconds(5) + " FPS, avg 5s";
    FPS.show();
}